<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('images.images') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <MediaGridCMS :media="media" />
            <b-row v-if="loadMore">
              <b-col>
                <p class="text-center">
                  <b-button variant="primary" @click="loadMoreData" class="my-4" size="lg">
                  {{ $t('images.load-more') }}
                  </b-button>
                </p>
              </b-col>
            </b-row>            
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import MediaGridCMS from '@/components/admin/_utils_/MediaGridCMS.vue'

export default {
  name: "PendingImages",
  components :{ NavBar, MediaGridCMS },
  data: function() {
    return {
      media: [],
      showOverlay: true,
      loadMore: false,
      page: 0
    }
  },
  created: async function() {
    await this.$http.get('/cms/api/media/image/deleted/?page=0', { headers: {Authorization: this.getAuthData().accessToken }})
    .then(response => {
      this.media = response.data.content;
      this.loadMore = response.data.hasNext;
      })
    .catch(error => {
      var message = this.handleError(error);
    })
    this.showOverlay = false;
  },

  methods: {
    loadMoreData: function() {
      this.page++;
      this.$http.get('/cms/api/media/image/deleted/?page=' + this.page).then(r => {
        //this.media = r.data.content;
        this.media = this.media.concat(r.data.content);
        this.loadMore = r.data.hasNext;
        this.showOverlay = false;
      }).catch(e => {
        var message = this.handleError(e);
      })
    }
  }
}
</script>
<style></style>
